import { defineStore } from 'pinia';
import {useI18n} from "vue-i18n";
import {useNuxtApp} from "#app";

export const useModalStore = defineStore('modal', {
    state: () => ({
        isVisible: false,
        title: '',
        content: '',
        size: undefined,
        okButtonName: '',
        cancelButtonName: '',
        okButtonHandler: undefined,
        cancelButtonHandler: undefined,
    }),
    actions: {
        openModal({ title, content, size, okButtonName, cancelButtonName, onOk, onCancel }) {
            this.isVisible = true;
            this.title = title || useNuxtApp().vueApp.$i18n?.global.t("common.modal.title.confirm");
            this.okButtonName = okButtonName || useNuxtApp().vueApp.$i18n?.global.t("common.button.ok");
            this.cancelButtonName = cancelButtonName || useNuxtApp().vueApp.$i18n?.global.t("common.button.cancel");

            this.okButtonHandler = onOk;
            this.cancelButtonHandler = onCancel;
            this.content = content;
            if (size) {
                this.size = size;
            }
        },
        initializeModal() {
            this.isVisible = false;
            this.title = '';
            this.content = '';
            this.size = undefined;
            this.okButtonName = '';
            this.cancelButtonName = '';
            this.okButtonHandler = undefined;
            this.cancelButtonHandler = undefined;
        },
        closeModal() {
            if (this.cancelButtonHandler) {
                this.cancelButtonHandler(() => {
                    this.initializeModal();
                });
            } else {
                this.initializeModal();
            }
        },
        confirmModal() {
            if (this.okButtonHandler) {
                this.okButtonHandler(() => {
                    this.initializeModal();
                });
            } else {
                this.initializeModal();
            }
        },
    },
});
