export async function downloadFileWithToken(
  token: string,
  filename: string,
  file: any,
) {
  try {
    const response: any = await customFetch(
      `/file/public/downloadWithToken?token=${token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      },
    );
    const url = window.URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (file) {
      file.dwldCo++;
    }
  } catch (error) {
    console.error("Error downloading file:", error);
  }
}
function getDocumentWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.body.clientWidth,
    document.documentElement.clientWidth,
  );
}
export function getImgUrl(
  img: any,
  width: any,
  widthMargin: any,
  forceWidth: any,
) {
  if (!img) {
    return "";
  }
  if (img.url) {
    return img.url;
  }
  if (Array.isArray(img)) {
    if (img.length === 0) {
      return "";
    }
    img = img[0];
  }
  const token = img.token || img;
  if (!token) {
    return "";
  }
  const config = useRuntimeConfig();
  const documentWidth = getDocumentWidth();
  let url =
    config.public.apiBaseUrl + "/file/public/imageWithToken?token=" + token;
  if (width) {
    if (!forceWidth) {
      if (parseInt(width) > documentWidth) {
        width = documentWidth;
        if (widthMargin) {
          width += widthMargin;
        }
      }
    }
    url += "&width=" + Math.round(width);
  }
  return url;
}

export function getImgUrlByTokens(tokens, defaultImage) {
  if (tokens && tokens.length > 0) {
    return getImgUrl(tokens[0]);
  }

  if (defaultImage.startsWith("/assets/")) {
    const { getAssetsImage } = useResources();
    return getAssetsImage(defaultImage);
  }
}
