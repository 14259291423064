const resources = import.meta.glob("~/assets/img/*", { eager: true });

export const useResources = () => {
  const getAssetsImage = (path: string) => {
    return resources[path]?.default || "";
  };

  return {
    getAssetsImage,
  };
};
